export enum StatusClass {
  Pending_Grid = 'grid-case-status-pending',
  Invalid_Grid = 'grid-case-status-invalid',
  Running_Grid = 'grid-case-status-running',
  Optimized_Grid = 'grid-case-status-optimized',
  Failed_Grid = 'grid-case-status-failed',
  NotFound_Grid = 'grid-case-status-not-found',
  Warning_Grid = 'grid-case-status-warning',

  Pending = 'case-status-pending',
  Invalid = 'case-status-invalid',
  Running = 'case-status-running',
  Optimized = 'case-status-optimized',
  Failed = 'case-status-failed',
  NotFound = 'case-status-not-found',
  Warning = 'case-status-warning'
}