import { CaseFilters, CaseStatus } from "../interface/case-managment.interface";

export const product = [
  {
    "Product": "C3",
    "Reference Names": "Propane"
  },
  {
    "Product": "Naphtha",
    "Reference Names": "Xyz"
  },
  {
    "Product": "NC4",
    "Reference Names": "Buatne"
  },
  {
    "Product": "Refrigerated LPG Mixture",
    "Reference Names": "Mix"
  }
]
export const deliveryTerm = [
  {
    "DealDetailDeliveryTerm": "CFR"
  },
  {
    "DealDetailDeliveryTerm": "DAP"
  },
  {
    "DealDetailDeliveryTerm": "FOB"
  }
]
export const intervalList = ['Full Month','1h', '2h', '1h & 2h']
export const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
export const contractCategory = [ "Ginga", "Worldwide" ]

export const booleanValues = ['TRUE', 'FALSE'];
export const quantityUom = ['bbl', 'mt', 'cpg'];

export const tradeDirection = ['Discharge', 'Load'];

export const liftOrDischarge = ['Lift', 'Discharge'];

export const vesselType = [
  'VLGC',
  'NeoPanamax', 
  'Panamax'
];

export const caseStatus: CaseStatus =
{
  0: "Pending",
  1: "Invalid",
  2: "Running",
  3: "Optimized",
  4: "Failed",
  5: "Optimized", //Optimized with Warning messages
};

export const DefaultGetCaseListRequest: CaseFilters = {
  "fromDate": "",
  "toDate": "",
  "owner": 0
};

export const canalDirection = [
  'North Bound', 
  'South Bound'
];


//TODO: These last dropdowns need to be verified or removed/replaced
export const strategy = [
  { "strategy": 'Bioko' },
  { "strategy": 'Eneos -TS0001' },
  { "strategy": 'Enterprise-TP0001' },
  { "strategy": 'Enterprise-TP0005' },
  { "strategy": 'Equinor -TP0003' },
  { "strategy": 'Escravos Equity-TP0004' },
  { "strategy": 'Escravos Falcon-TP0001' },
  { "strategy": 'Gunvor-TS0001' },
  { "strategy": 'Gunvor-TS0002' },
  { "strategy": 'Jinneng -TS0001' },
  { "strategy": 'Jovo-TS0001' },
  { "strategy": 'KPC-TP0014' },
  { "strategy": 'Petrochina-TS0001' },
  { "strategy": 'Petrochina-TS0002' },
  { "strategy": 'PetrochinaHK-TS0001' },
  { "strategy": 'Sanha-TP0008' },
  { "strategy": 'Satellite -TP0001' },
  { "strategy": 'SHV-TP0001' },
  { "strategy": 'SK SHORT-TS0001' },
  { "strategy": 'SK-TP0002' },
  { "strategy": 'SK-TS0001' },
  { "strategy": 'Soyo-TP0003' },
  { "strategy": 'Targa-TP0002' },
  { "strategy": 'Targa-TP0003' },
  { "strategy": 'Unipec 1-TS0001' },
  { "strategy": 'Unipec 2-TS0001' },
  { "strategy": 'Spot 1' },
  { "strategy": 'Spot 2' },
  { "strategy": 'Spot 3' },
  { "strategy": 'Spot 4' },
  { "strategy": 'Spot 5' },
  { "strategy": 'Spot 6' },
  { "strategy": 'Spot 7' },
  { "strategy": 'Spot 8' },
  { "strategy": 'Spot 9' },
  { "strategy": 'Spot 10' },
]
