import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalGuardService } from '@cvx/cal-angular';
import { NgloComponent } from './nglo/nglo.component';
import { NgloHomeComponent } from './nglo/nglo-home/nglo-home.component';

const routes: Routes = [
  {
    path: 'nglo',
    component: NgloComponent,
    loadChildren: () => import('./nglo/nglo.module').then(m => m.NgloModule),
    canActivate:[
          CalGuardService
        ]
  },
  {
    path: '',
    component: NgloHomeComponent, //CMOHomeComponent When we want CMO Home Screen
    pathMatch: 'full',
    canActivate:[
          CalGuardService
        ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
