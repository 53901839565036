<np-header *ngIf="loggedIn && showHeader"></np-header>
<!-- Site-Specific Navigation -->
<div id="nav-container" *ngIf="showHeader && loggedIn && isNGLO" [ngSwitch]="env">
    <np-menu class="np-menu-dev" *ngSwitchCase="'development'" [location]="NavigationLocation.SiteSpecific"></np-menu>
    <np-menu class="np-menu-test" *ngSwitchCase="'test'" [location]="NavigationLocation.SiteSpecific"></np-menu>
    <np-menu class="mainnav" *ngSwitchDefault [location]="NavigationLocation.SiteSpecific"></np-menu>
</div>
<np-notification [show]="toastObj.show" [data]="toastObj"></np-notification> 
<router-outlet (activate)='onActivate($event)'>
    <np-loader *ngIf="isLoading"></np-loader>
</router-outlet>
