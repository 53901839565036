import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CaseHeader, ModalEvent } from 'src/app/shared/interface/case-managment.interface';
import { CaseManagementService } from 'src/app/shared/services/case-management.service';
import { IsLoadingService } from 'src/app/shared/services/is-loading.service';
import { Utilities } from '../../../../shared/utilities/utilities';
import { DraggableService } from 'src/app/shared/services/draggable-modal.service';
import { UserService } from 'src/app/shared/services/user.service';
import { Constants } from 'src/app/shared/constants/constants';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-case-set-up-modal',
  templateUrl: './case-set-up-modal.component.html',
  styleUrls: ['./case-set-up-modal.component.scss'],
  providers: [DraggableService]
})
export class CaseSetUpModalComponent implements OnInit {
  @Input() closeModal!:ModalEvent;
  @Input() selectedCaseDetail: any;
  @Input() parentCaseDetails!: any;
  @Input() refCaseDetails!: any; 
  @Output() closePopup= new EventEmitter();
  caseSetUpForm!: FormGroup;
  nameMaxLength: number= 100;
  descriptionMaxLength: number= 500;
  nameCount: string | null = '';
  descriptionCount: string | null= '';
  userName!:string;
  optimizerModelList: any[] = ["LP", "Heuristic"];
  currentModelType: string = "LP";
  readonly caseNamePattern = '^([\\w]|[^\\\\/\\[\\]])+$'; // restricted special chars: / \ [ ]
  readonly caseNamePatternRegExp: RegExp = new RegExp(this.caseNamePattern); 
  readonly caseDescPattern = '\\S';
  readonly caseDescPatternRegExp: RegExp = new RegExp(this.caseDescPattern);

  constructor(private formBuilder: FormBuilder, private utilities: Utilities,
    private isLoadingSr: IsLoadingService,
    private casemanagementservice: CaseManagementService, 
    private draggableService: DraggableService,
    private startingElement: ElementRef,
    private router: Router,
    private userService: UserService) { }

  initializeCaseSetUpForm() {
    this.caseSetUpForm = this.formBuilder.group({
      caseName: ['', [Validators.required, Validators.maxLength(this.nameMaxLength), Validators.pattern(this.caseNamePatternRegExp)]], 
      createdDate: [`${new Date().getFullYear()}-${new Date().getMonth()+1}-${new Date().getDate()}`, [Validators.required]],
      caseDescription: ['', [Validators.required, Validators.maxLength(this.descriptionMaxLength),  Validators.pattern(this.caseDescPatternRegExp)]],
      caseOptimizerModel: ['LP', [Validators.required]],
      isInCharterStatic: [false],
      isOutCharterStatic: [false]
    });
  }

  ngOnInit(): void {
    this.initializeCaseSetUpForm();
    this.forCaseCount(this.selectedCaseDetail);
    this.descriptionCount = this.utilities.textLimiter(0,this.descriptionMaxLength); 
    this.userName = this.userService.userName;
    this.draggableService.runService(this.startingElement);
  }

  forCaseCount(selectedCaseDetail:any) {
    let caseName = '', caseDesc = '';
    if (selectedCaseDetail?.caseName) {
      if (selectedCaseDetail['caseName']?.length < 24 ) {
        caseName = selectedCaseDetail['caseName'] + ' (copy)';
      }
      else {
        caseName = selectedCaseDetail['caseName'];
      }
      caseDesc = `Copy of ${selectedCaseDetail.caseName}`;
    }
    if(selectedCaseDetail?.modelType){
      this.currentModelType = selectedCaseDetail['modelType'];
    }
    else {
      this.currentModelType = "LP";
    }
    if (this.selectedCaseDetail) {
      this.caseSetUpForm.patchValue({ caseName: caseName, caseDescription: caseDesc, optimizerModelType: this.currentModelType });
    }
    this.nameCount= this.utilities.textLimiter(caseName?.length,this.nameMaxLength);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['closeModal'] && changes['closeModal'].currentValue.modal === 'casesetup' && !changes['closeModal'].currentValue.open) {
      this.resetPopup();
    }
    if (changes?.['selectedCaseDetail']?.currentValue) {
      let caseDetails = changes['selectedCaseDetail']?.currentValue;
      this.forCaseCount(caseDetails);
    } else if (this.selectedCaseDetail) {
     this.forCaseCount(this.selectedCaseDetail);
    }
  }

  wordCounter(value:string, limit:number,field: string) {
    switch(field) {
      case 'name':
         this.nameCount = this.utilities.textLimiter(value.length,limit);
         break;
      case 'description':
         this.descriptionCount = this.utilities.textLimiter(value.length,limit);
         break;
    }
  }

  async createCase() {
    this.isLoadingSr.setLoading(true);
    let parentCaseId;
    let isCopiedCase = false;
    if (this.selectedCaseDetail && Object.keys(this.selectedCaseDetail).includes('status')) {
      isCopiedCase = true;
      parentCaseId = this.selectedCaseDetail?.id;
    } else {
      parentCaseId = this.parentCaseDetails.parentCaseId;
    }
    if (this.caseSetUpForm.status !== Constants.STATUS_INVALID) {
      let caseHeaderPayload: CaseHeader = {
        id: '',
        parentCaseId,
        caseName: this.caseSetUpForm.value['caseName'],
        status: 'Pending',
        description: this.caseSetUpForm.value['caseDescription'],
        startDate: this.caseSetUpForm.value['createdDate'],
        // endDate: '',
        marking: 'XIX',
        optimizationModel: 'non static model',
        // tags: this.caseSetUpForm.value['tags'],
        netEngineOnly: true,
        includeNonFlow: true,
        // planingTimeLine: '2022-10-31T11:56:28.540Z',
        createdBy: this.userName,
        creationDate: this.caseSetUpForm.value['createdDate'],
        modifiedBy: this.userName,
        modelType: this.caseSetUpForm.value["caseOptimizerModel"],
        // modifiedDate: '2022-10-31T11:56:28.540Z',
        // optimizedDate: '',
        owner: 0,
        isCopiedCase,
        isInCharterStatic: this.caseSetUpForm.value['isInCharterStatic'] ? true : false,
        isOutCharterStatic: this.caseSetUpForm.value['isOutCharterStatic'] ? true : false
      };

      const caseHeader: CaseHeader = await lastValueFrom(this.casemanagementservice.CreateCase(caseHeaderPayload));
      // create pricing calculator records only for newly created case
      if (!isCopiedCase) {
        const pricingCalculator = await lastValueFrom(this.casemanagementservice.getZemaPricingForCalculator(caseHeader.id));
        await lastValueFrom(this.casemanagementservice.savePricingCalculatorData(pricingCalculator));
      }
      this.isLoadingSr.setLoading(false);
      this.router.navigate([`nglo/caseManagement/case/${caseHeader.id}`]);
      this.resetPopup();
      this.closePopup.emit(false);
    } else {
      this.isLoadingSr.setLoading(false);
    }
  }
    
  resetPopup() {
    if(this.caseSetUpForm) {
      this.caseSetUpForm.reset();
      this.caseSetUpForm.patchValue({
        caseName: '', 
        createdDate:`${new Date().getFullYear()}-${new Date().getMonth()+1}-${new Date().getDate()}`, 
        caseDescription: '',
        caseOptimizerModel: 'LP'
      });
      this.nameCount= this.utilities.textLimiter(0,this.nameMaxLength);
      this.descriptionCount = this.utilities.textLimiter(0,this.descriptionMaxLength);
      this.currentModelType = "LP";
    }
    
  }

}
