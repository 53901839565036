import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseSetUpModalComponent } from 'src/app/nglo/case-management/case-log/case-set-up-modal/case-set-up-modal.component';
import { ReportsModalComponent } from 'src/app/nglo/case-management/case-log/reports-modal/reports-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorMessagesDirective } from '../directives/error-messages.directive';
import { FormSubmitValidationMsgDirective } from '../directives/form-submit-validation-msg.directive';
import { FormControlValidationMsgDirective } from '../directives/form-control-validation-msg.directive';
import { HttpClientModule } from '@angular/common/http';
import { NpModalComponent } from '../components/np-modal/np-modal.component';
import { NpLoaderComponent } from '../components/np-loader/np-loader.component';
import { CustomUploadComponent } from '../components/custom-upload/custom-upload.component';
import { FieldNamePipe } from '../pipes/field-name.pipe';
import { TooltipDirective } from '../pricing/tooltip.directive';
import { SelectDropdownComponent } from '../components/select-dropdown/select-dropdown.component';


@NgModule({
  declarations: [
    NpModalComponent,
    NpLoaderComponent,
    CustomUploadComponent,
    CaseSetUpModalComponent,
    ReportsModalComponent,
    ErrorMessagesDirective,
    FormControlValidationMsgDirective,
    FormSubmitValidationMsgDirective,
    FieldNamePipe,
    TooltipDirective,
    SelectDropdownComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  exports: [
    NpModalComponent,
    NpLoaderComponent,
    CustomUploadComponent,
    CaseSetUpModalComponent,
    ReportsModalComponent,
    ErrorMessagesDirective,
    FormControlValidationMsgDirective,
    FormSubmitValidationMsgDirective,
    TooltipDirective,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FieldNamePipe,
    SelectDropdownComponent
  ]
})
export class SharedModule { }
