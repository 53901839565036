import { Component, Input } from '@angular/core';

@Component({
  selector: 'np-loader',
  templateUrl: './np-loader.component.html',
  styleUrls: ['./np-loader.component.scss']
})
export class NpLoaderComponent {
  @Input() type:string = 'full-page';

}
