import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { firstValueFrom, from, lastValueFrom, Observable} from 'rxjs';

import { AudTokenService } from './shared/services/aud-token.service';
import { environment } from 'src/environments/environment';
import { CalAngularService } from '@cvx/cal-angular';

@Injectable()
export class AuthTokenServiceInterceptor implements HttpInterceptor {
  constructor(private audTokenSr: AudTokenService,
    private authService: CalAngularService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.handle(request, next))
  }

  async handle(request: HttpRequest<any>, next: HttpHandler) {
    const tok = await firstValueFrom(this.authService.getAADToken([environment.tokenURL], false));
    const requesTypeDef = (this.audTokenSr.getIsDownload()) ? 'blob' : 'json';
    request = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${tok}`),
      responseType: requesTypeDef
    });
    
    return await lastValueFrom(next.handle(request));
  }
}