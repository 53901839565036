// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environmentName: 'dev',
  securityAdminGroupName: 'NGLO-Admin-NonProd',
  securityUserGroupName: 'NGLO-User-NonProd',
  securityReadOnlyGroupName: 'NGLO-ReadOnly-NonProd',
  workspaceID: '94f0a8f1-9791-4eef-973d-b64c56d22555',
  OptimizedCaseListReportId: 'b7f965c7-3f4c-48e7-84db-5ed91bbe1a8a',
  OptimizedCaseListSectionId: 'ReportSectioncb21a5b50c0fc920a848',
  VoyageListSectionId: 'ReportSection4748db7c4c70d6b5899a',
  SupplyDemandEconomicsSectionId: 'ReportSection95e6bcf07e54e4996ee3',
  BasePlanSectionId: 'ReportSection7b747e2ce7cc40b903b',
  CaseComparisonSectionId: 'ReportSectiondbd565709a42a4dee91e',
  datasetReportOne: 'dd4c30f1-5b3a-47a9-b9d9-6e038acf3102',
  reportURL: 'https://app.powerbi.com/groups/94f0a8f1-9791-4eef-973d-b64c56d22555/lineage',
  tokenURL: 'https://nglo-api-test.azure.chevron.com/user_impersonation',
  clientID: "858f8de9-371e-45fd-8b0d-6fe1e485ea88",
  calRedirectURI: 'https://nglo-dev.azure.chevron.com',
  apiSettingsEP: 'https://cscm-nglo-dev-settings-af-cvx.azurewebsites.net',
  apiCaseDetailEP: 'https://cscm-nglo-dev-casedetails-af-cvx.azurewebsites.net',
  apiReferenceDataEP: 'https://cscm-nglo-dev-referencedata-af-cvx.azurewebsites.net',
  apiCaseOptimizationEP: 'https://cscm-nglo-dev-caseoptimization-af-cvx.azurewebsites.net',
  apiCaseOrchestrationEP: 'https://cscm-nglo-dev-caseorchestartion-af-cvx.azurewebsites.net',
  apiCaseHeaderEP: 'https://cscm-nglo-dev-caseheader-af-cvx.azurewebsites.net',
  apiDataScienceOptmizerEP:'https://cscm-nglo-dev-optimizer-af-cvx.azurewebsites.net'
};
