<form [formGroup]="caseSetUpForm" class="case-setup branded">
  <div class="form-row pb-2 pr-2">
    <div class="form-group col-md-12">
      <span class="d-flex justify-content-between">
        <label for="caseName" class="label no-brand">case name</label>
        <label for="countAndLimit" class="label">{{nameCount}}</label>
      </span>
      <input type="text" formControlName="caseName" class="form-control text-field no-brand" id="caseName"
        placeholder="case name" #name (keyup)="wordCounter(name.value,nameMaxLength,'name')" [maxlength]="nameMaxLength"
        appFormControlValidationMsg>
    </div>
  </div>
  <div class="form-row pb-2 pr-2">
    <div class="form-group col-md-12">
      <span class="d-flex justify-content-between">
        <label for="caseOptimizerModel" class="label no-brand">case optimizer model</label>
      </span>
        <app-select-dropdown #customDropdown *ngIf="optimizerModelList" [items]="optimizerModelList" [defaultValue] = "currentModelType" formControlName="caseOptimizerModel" appFormControlValidationMsg></app-select-dropdown>
    </div>
  </div>
  <div class="form-row pb-1 pr-2">
    <div class="form-group col-md-12">
      <span class="d-flex justify-content-between">
        <label for="caseDescription" class="label no-brand">
          case description
        </label>
        <label for="countAndLimit" class="label no-brand">{{descriptionCount}}</label>
      </span>
      <textarea rows="5" formControlName="caseDescription" class="form-control case-description no-brand" #description
        (keyup)="wordCounter(description.value,descriptionMaxLength,'description')" [maxlength]="descriptionMaxLength"
        appFormControlValidationMsg></textarea>
    </div>
  </div>
  <div class="form-row pb-1 pr-2">
    <div class="form-group col-md-6 mb-0">
      <p class="mb-0"><input type="checkbox" name="isInCharterStatic" formControlName="isInCharterStatic"
          id="isInCharterStatic" value="true">
        <label class="label" for="isInCharterStatic">Overwrite Incharter</label>
      </p>
    </div>
    <div class="form-group col-md-6 mb-0">
      <p class="mb-0"><input type="checkbox" name="isOutCharterStatic" formControlName="isOutCharterStatic"
          id="isOutCharterStatic" value="true">
        <label class="label" for="isOutCharterStatic">Overwrite Outcharter</label>
      </p>
    </div>
  </div>
</form>
<div class="btn-wrap d-flex justify-content-start">
  <button type="button" [disabled]="caseSetUpForm.invalid" (click)="createCase()" class="button primary"
    appFormSubmitValidationMsg [validationControl]="caseSetUpForm"> create </button>
</div>