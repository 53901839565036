import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { DefaultGetCaseListRequest } from '../field-values/dropdown-data';
import {
  BasePlanDetails,
  CaseHeader,
  OptimizeCase,
  PublishCase,
  RowDetails,
} from '../interface/case-managment.interface';
import { ApiService } from './api.service';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CaseManagementService {
  constructor(private apiService: ApiService) { }

  getCaseHeaderDetails(caseId: string) {
    return this.apiService.get(`${environment.apiCaseHeaderEP}/api/GetCaseHeaderByIdWithOutputCases?caseId=${caseId}`)
    .pipe(
      map((response) => {
        return response;
      })
    );
  }

  CreateCase(caseHeader: CaseHeader): Observable<CaseHeader> {
    return this.apiService
      .post(`${environment.apiCaseOrchestrationEP}/api/CreateCase`, caseHeader)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getZemaPricingForCalculator(caseId: any ): Observable<CaseHeader>{
    return this.apiService.get(`${environment.apiCaseOrchestrationEP}/api/GetPricingCalculator?caseId=${caseId}`)
    .pipe(
      map((response) => {
        return response;
      })
    );
  }
  
  updateCaseHeader(caseHeader: CaseHeader): Observable<CaseHeader> {
    return this.apiService
      .post(`${environment.apiCaseHeaderEP}/api/SaveCaseHeader`, caseHeader)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  savePricingCalculatorData(params:any ): Observable<any>{
    return this.apiService
    .post(`${environment.apiCaseDetailEP}/api/UpsertPricingCalculatorDetails`,params)
    .pipe(map(response => {
      return response;
   }));
  }
  getCaseList(requestBody = DefaultGetCaseListRequest): Observable<any> {
    return this.apiService
      .post(`${environment.apiCaseHeaderEP}/api/GetCaseList`, requestBody)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
  createAutogeneratedFob(caseId: string){
    return this.apiService.post(`${environment.apiCaseOrchestrationEP}/api/CreateAutogeneratedFob?caseId=${caseId}`).pipe(map(response => { return response; }));

  }
  createDealsAndMoveToBase(dealData: BasePlanDetails[], caseId: string): Observable<any> {
    return this.apiService.post(`${environment.apiCaseOrchestrationEP}/api/AddDealToBasePlan?caseId=${caseId}`, dealData).pipe(map(response => { return response; }));
  }

  getCaseBasePlanDetails(caseId: string): Observable<BasePlanDetails[]> {
    return this.apiService
      .get(
        `${environment.apiCaseDetailEP}/api/GetCaseBasePlanDetails?caseId=${caseId}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  validateCase(caseId: string) {
    return this.apiService
      .get(
        `${environment.apiCaseOptimizationEP}/api/ValidateCase?caseId=${caseId}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  optimizerValidateCase(data: any) {
    return this.apiService
    .postHeader(`${environment.apiDataScienceOptmizerEP}/api/CaseOptimization`, data)
    .pipe(
      map((res) => {
        return res;
      })
    );
  }
  validateResponse(data: any){
    return this.apiService
    .post(`${environment.apiCaseOrchestrationEP}/api/SaveValidationMessage?`, data)
    .pipe(
      map((res) => {
        return res;
      })
    );
  }
  updateCaseBasePlanDetails(
    basePlanDetails: BasePlanDetails[]
  ): Observable<BasePlanDetails[]> {
    return this.apiService
      .put(
        `${environment.apiCaseDetailEP}/api/UpdateCaseBasePlanDetails`,
        basePlanDetails
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteRowById(caseDetails: RowDetails): Observable<any> {
    return this.apiService
      .delete(
        `${environment.apiCaseDetailEP}/api/DeleteCaseBasePlanById`,
        caseDetails
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  createDeals(dealData: BasePlanDetails[]): Observable<any> {
    return this.apiService
      .post(`${environment.apiSettingsEP}/api/CreateDeals`, dealData)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  optimizeCase(caseDetail: OptimizeCase): Observable<any> {
    return this.apiService
      .post(
        `${environment.apiCaseOptimizationEP}/api/OptimizerInput_HttpStart`,
        caseDetail
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteCase(caseIds: string[]): Observable<any> {
    // updating it to post as per backend team
    return this.apiService
      .post(`${environment.apiCaseHeaderEP}/api/DeleteCase`, caseIds)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteCaseHeaders(caseIds: string[]): Observable<any> {
    return this.apiService
      .delete(`${environment.apiCaseOrchestrationEP}/api/DeleteCaseHeaders`, caseIds)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  downloadCase(caseId: string): Observable<any> {
    return this.apiService.get(`${environment.apiCaseDetailEP}/api/DownloadCaseDetails?caseId=${caseId}`)
      .pipe(map(response => {
        return response;
      }));
  }

  downloadCaseJson(caseId: string): Observable<any> {
    return this.apiService.get(`${environment.apiCaseOptimizationEP}/api/DownloadCaseJson?caseId=${caseId}`)
      .pipe(map(response => {
        return response;
      }));
  }

  downloadOptimizedCaseVisuals(caseId: string): Observable<any> {
    return this.apiService.get(`${environment.apiCaseOptimizationEP}/api/DownloadCaseNetworkVisuals?caseId=${caseId}`)
      .pipe(map(response => {
        return response;
      }));
  }

  uploadCase(uploadJson: any): Observable<any> {
    return this.apiService
      .post(`${environment.apiCaseDetailEP}/api/UploadCaseDetails`, uploadJson)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  publishCase(publishCase: PublishCase): Observable<PublishCase> {
    return this.apiService
      .post(`${environment.apiCaseOrchestrationEP}/api/PublishCase?`, publishCase)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  refreshPowerBiReports(requestBody: any): Observable<PublishCase> {
    return this.apiService
      .post(`${environment.apiCaseOrchestrationEP}/api/PowerBIRefresh`, requestBody)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getPowerBIRefreshHistory(requestBody: any): Observable<PublishCase> {
    return this.apiService
      .post(
        `${environment.apiCaseOrchestrationEP}/api/GetPowerBIRefreshHistory`, requestBody
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}
