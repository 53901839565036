import { Injectable } from '@angular/core';
import { ColumnState } from 'ag-grid-community';


@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  public sessionStorageData:any  = [];

  retriveColumnState(path:string){
    this.sessionStorageData  = sessionStorage.getItem(path)
    if(this.sessionStorageData){
        this.sessionStorageData = JSON.parse(this.sessionStorageData)
        return this.sessionStorageData;
    }
    return '';
  }

  saveColState(path:string, colState:ColumnState[] ){
    sessionStorage.setItem(path,JSON.stringify(colState));
  }

  removeColumnState(path: string) {
    sessionStorage.removeItem(path);
  }
}
