
<div class="row">
  <div class="col-sm-6" *ngFor="let report of reports.slice(0, reports.length / 2)">
    <input type="radio" name="flexRadioDefault" id="flexRadioDefault1" [(ngModel)]="selectedReport" value="{{ report.value }}" [disabled]="report.isEnable">
      &nbsp;<label class="form-check-label" for="flexRadioDefault1">{{ report.name }}</label>
      <br>
  </div>
  <div class="col-sm-6" *ngFor="let report of reports.slice(reports.length / 2)">
    <input type="radio" name="flexRadioDefault" id="flexRadioDefault1" [(ngModel)]="selectedReport" value="{{ report.value }}" [disabled]="report.isEnable" >
      &nbsp;<label class="form-check-label" for="flexRadioDefault1">{{ report.name }}</label>
      <br>
  </div>
</div>


<div class="btn-wrap d-flex justify-content-start">
  <button type="button" (click)="close()" data-dismiss="modal" class="button secondary"> close </button>
  <button type="button" (click)="generate()" class="button primary" > generate </button>
</div>