import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsLoadingService {
  private isLoading$ = new BehaviorSubject<boolean>(false);
  checkLoader$ = this.isLoading$.asObservable();

  setLoading(flag: boolean) {
    this.isLoading$.next(flag);
  }
}
