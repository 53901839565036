import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationMsgService {
  private errorMessages:any = {
    'required' : "This field is required.",
    'pattern' : "This field contains characters that are not allowed.",
    'minlength' : "This field must have 8 characters",
    'maxlength' : "This field can have maximum 30 characters",
    
  }
  
  public getValidationMsg(validationId:string):string{
    return this.errorMessages[validationId];
  }

  
}
