import { Component, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-custom-upload',
  templateUrl: './custom-upload.component.html',
  styleUrls: ['./custom-upload.component.scss']
})
export class CustomUploadComponent{
  @Output() changeEvent = new EventEmitter<any>();
  @ViewChild('customFile') fileUpload: any;
  onChange(event:any) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      console.log("FileUpload -> files", fileList);
    }
    this.changeEvent.emit(event);
    event.target.value = '';
  }
  openUpload(){
    let elem = this.fileUpload.nativeElement;
    elem.click();
  }
}
