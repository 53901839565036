import { ElementRef, Injectable, QueryList, Renderer2 } from "@angular/core";
import { formatNumber } from "@angular/common";
import { FormGroup } from "@angular/forms";
import { GridApi, ICellEditorParams } from "ag-grid-community";
import { Observable, Observer, Subject } from "rxjs";
import { StatusClass } from "../enums/enums";
import { caseStatus } from "../field-values/dropdown-data";
import { ToastService } from 'src/app/shared/services/toast.service';
import { CaseDetail, FieldsRef, RefreshStatusDetail, SelectedTabFunction } from "../interface/case-managment.interface";
import { AudTokenService } from "../services/aud-token.service";
import { CaseManagementService } from "../services/case-management.service";
import { SessionStorageService } from "../services/session-storage.service";
import { Constants } from "../constants/constants";
@Injectable({ providedIn: 'root' })

export class Utilities {
  sendDataToChildComp$ = new Subject<SelectedTabFunction>();
  selectedTabFunction: any = '';
  unsubscribeList: any = [];
  caseDetail: any;

  constructor(
    private toastSer: ToastService,
    private audTokenSr: AudTokenService,
    private casemanagementservice: CaseManagementService,
    private storageService: SessionStorageService
  ) { }

  textLimiter(count: number, limit: number): string | null {
    let formattedLimter = '';
    formattedLimter = count <= limit ? `${count}/${limit}` : '';
    return formattedLimter;
  }

  //logic to convert number to decimals
  decimalToTwo(params: any) {
    if (typeof params === 'object' && !params.data) return;
    let value = params;
    if (params !== null && typeof params === 'object') {
      value = params.value;
    }
    if (value && value !== "" && !Number.isInteger(+value)) {
      return Number.parseFloat(value).toFixed(2);
    }
    else if (value && value !== "" && Number.isInteger(+value)) {
      return value;
    }
    return 0;
  }
  //logic to convert number to decimals
  commaSeprated(params: any) {
    let value;
    if (params !== null && typeof params === 'object') {
      value = params.value;
    }
    else {
      value = params.toString();
      if (value.indexOf(",") > 0) {
        value = value.replace(/,/g, "")
      }
    }
    if (value || value === 0) {
      return formatNumber(value.toString(), 'en-US', '1.2-2');
    }
    return '';
  }

  // Ag grid Error toast msg   
  errorHandlerFn = (params: ICellEditorParams, flag: string) => {
    let columnName = params.colDef.field || '';
    this.toastSer.setToastNotification({ type: 'error', show: true, msg: `${flag} value expected in field ${columnName}` });
  }

  validateValue(params: any, numericFields?: string[], stringFields?: string[], decimalFields?: string[]): boolean {
    let data = params.data;
    let field = params.colDef.field;
    let isValid: boolean = false;
    if (numericFields?.length) {
      if (numericFields.find(value => value === field)) {
        if (!isNaN(params.newValue) && (params.newValue % 1 === 0)) {
          data[field] = params.newValue;
        } else {
          data[field] = params.oldValue;
        }
      }
    }
    if (decimalFields?.length) {
      if (decimalFields.find(value => value === field)) {
        data[field] = !isNaN(params.newValue) ? params.newValue : params.oldValue;
      }
    }
    if(data[field] === params.oldValue) {
      isValid = false
    }
    else {
      isValid = true
    }
    return isValid;
  }
  sendDataToChild(selectedTabFunction: SelectedTabFunction) {
    this.sendDataToChildComp$.next(selectedTabFunction);
  }

  getDataFromParent(): Observable<SelectedTabFunction> {
    return this.sendDataToChildComp$.asObservable();
  }

  clearDataToChild() {
    this.sendDataToChildComp$.next({
      tab: '',
      function: '',
      type: '',
      data: ''
    });
  }

  addUnsubscribleList(temp: any) {
    this.unsubscribeList.push(temp);
  }
  
  unsubscribeDataToChild() {
    if (this.unsubscribeList?.length) {
      this.unsubscribeList.forEach((item: any) => {
        item?.unsubscribe();
      })
      this.unsubscribeList = [];
    }
  }

  // Case page enable disable save button 
  private editableData = new Subject<boolean>();
  updateEnableDisableSaveButton = this.editableData.asObservable();
  passEnableDisableValue(obj: any) {
    this.editableData.next(obj);
  }

  //Reset Grid Function
  private resetFilterGrid = new Subject<boolean>();
  gridResetFilter = this.resetFilterGrid.asObservable();
  resetGridFilter(comp: any, resetStatus:boolean) {
    let dataObj:any = {tabName:comp,resetStatus:resetStatus}
    this.resetFilterGrid.next(dataObj);
  }

  //Reset Grid Function
  private tabSwitch = new Subject<boolean>();
  tabSwitched = this.tabSwitch.asObservable();
  switchTab(comp: any) {
    this.tabSwitch.next(comp);
  }

  //Reset Grid Function
  private gridQuickFilter = new Subject<boolean>();
  quickFilter = this.gridQuickFilter.asObservable();
  setQuickFilter(comp: any) {
    this.gridQuickFilter.next(comp);
  }
  private savePricingCalculator = new Subject<string>();
  SavePricingCalculator= this.savePricingCalculator.asObservable();
  pricingCalculatorSave(comp: any) {
    this.savePricingCalculator.next(comp);
  }
  // Update Tabs
  updateComponent(comp: string) {
    switch(comp){
      case Constants.PRICING_TAB:
        this.callPricing(comp);
        break;
      case Constants.VESSEL_INFO_TAB:
        this.callVesselInfo(comp);
        break;
      case Constants.IN_CHARTER_SCHEDULE_TAB:
        this.callInCharterSchedule(comp);
        break;
      case Constants.OUT_CHARTER_SCHEDULE_TAB:
        this.callOutCharterSchedule(comp);
        break
      case Constants.SUPPLY_DEMAND_TAB:
        this.callSupplyDemand(comp);
        break;
      case Constants.PRICING_CALCULATOR_TAB:
        this.callPricingCalculator(comp);
        break;
      case Constants.ASIA_NETBACK_CALCULATOR_TAB:
        this.callAsiaNetbackCalculator(comp);
        break;
      case Constants.AUCTION_SLOT_PRICING:
        this.callAuctionSlotPricing(comp);
    }
  }

  highlightUpdatedCell(params: any, isValidValue: boolean, gridApi: GridApi) {
    if (isValidValue) {
      if (params.oldValue !== params.newValue) {
        let column = params.column.colDef.field;
        params.data['modifiedRow' + params.rowIndex + 'andCellKey' + column] =
          true;
        params.column.colDef.cellClass = this.getHighlightClass;
        params.api.refreshCells({
          force: true,
          columns: [column],
          rowNodes: [params.node],
        });
      }
      gridApi.setFocusedCell(params.node.rowIndex, params.column.colId);
    } else {
      gridApi.startEditingCell({
        rowIndex: params.rowIndex,
        colKey: params.column.colId,
      });
    }
  }

  getHighlightClass = (params: any, gridApi: GridApi) => {
    if (
      params?.data &&
      params.data[
      'modifiedRow' +
      params.node.rowIndex +
      'andCellKey' +
      params.column.colDef.field
      ]
    ) {
  
      return 'highlight-updated-value';
    } else {
      return null;
    }
  };


  // Run Optimizer 
  private optimizeCase = new Subject<boolean>();
  updateOptimizeCase = this.optimizeCase.asObservable();
  callOptimizer(obj: any) {
    this.optimizeCase.next(obj);
  }

  private pricingCalculatorUpdate = new Subject<boolean>();
  updatePricingCalculator= this.pricingCalculatorUpdate.asObservable();
  callPricingCalculator(obj: any) {
    this.pricingCalculatorUpdate.next(obj);
  }
  // Update Asia Netback Calc
  private asiaNetbackCalculatorUpdate = new Subject<boolean>();
  asiaNetbackCalculator = this.asiaNetbackCalculatorUpdate.asObservable();
  callAsiaNetbackCalculator(obj: any) {
    this.asiaNetbackCalculatorUpdate.next(obj);
  }
  // Update Auction Slot Pricing
  private auctionSlotPricingUpdate = new Subject<boolean>();
  auctionSlotPricing = this.auctionSlotPricingUpdate.asObservable();
  callAuctionSlotPricing(obj: any) {
    this.auctionSlotPricingUpdate.next(obj);
  }

  // Update Contract Exclusions
  private pricingUpdate = new Subject<boolean>();
  updatePricing = this.pricingUpdate.asObservable();
  callPricing(obj: any) {
    this.pricingUpdate.next(obj);
  }

  // Update Contract Exclusions
  private vesselInfoUpdate = new Subject<boolean>();
  updateVesselInfo = this.vesselInfoUpdate.asObservable();
  callVesselInfo(obj: any) {
    this.vesselInfoUpdate.next(obj);
  }

  // Update Supply Demand
  private supplyDemandUpdate = new Subject<boolean>();
  updateSupplyDemand = this.supplyDemandUpdate.asObservable();
  callSupplyDemand(obj: any) {
    this.supplyDemandUpdate.next(obj);
  }
  // Update Incharter Schedule
  private inCharterScheduleUpdate = new Subject<boolean>();
  updateInCharterSchedule = this.inCharterScheduleUpdate.asObservable();
  callInCharterSchedule(obj: any) {
    this.inCharterScheduleUpdate.next(obj);
  }

  // Update Incharter Schedule
  private outCharterScheduleUpdate = new Subject<boolean>();
  updateOutCharterSchedule = this.outCharterScheduleUpdate.asObservable();
  callOutCharterSchedule(obj: any) {
    this.outCharterScheduleUpdate.next(obj);
  }

  resetDropDownValues(
    dropdownEleRefs: QueryList<ElementRef>,
    renderer2: Renderer2,
    formGroup: FormGroup
  ) {
    for (const nativeEle of dropdownEleRefs['_results']) {
      // Assigning default values as per values defined in formControl after doing reset in patchValue method
      let defaultValue = 'select';
      const formGroupValue =
        formGroup.value[
        nativeEle.nativeElement.attributes.formcontrolname.value
        ];
      if (formGroupValue) {
        defaultValue = formGroupValue;
      }
      if (nativeEle.nativeElement.nodeName === 'SELECT') {
        this.dropDownSelected(renderer2, nativeEle, defaultValue)
      }
    }
  }
  dropDownSelected(renderer2: Renderer2, nativeEle: any, defaultValue: any) {
    let parent = renderer2.parentNode(nativeEle.nativeElement);
    let dropdownEle = parent.querySelector('.dropdown');
    if (dropdownEle) {
      let buttonEle = dropdownEle.querySelector('button');
      let liEle = dropdownEle.querySelectorAll('ul > li');
      for (const ele of liEle) {
        if (ele.ariaSelected) {
          renderer2.removeClass(ele, 'aria-selected');
          renderer2.setAttribute(ele, 'aria-selected', 'false');
        }
        if (ele.innerHTML === defaultValue) {
          renderer2.addClass(ele, 'aria-selected');
          renderer2.setAttribute(ele, 'aria-selected', 'true');
        }
      }
      renderer2.setProperty(buttonEle, 'innerHTML', defaultValue);
    }
  }
  patchDropdownValue(
    dropdownEleRefs: QueryList<ElementRef>,
    renderer2: Renderer2,
    formControlName: string,
    value: string | boolean
  ) {
    let defaultValue = 'select';
    if (value === '' || value === null) {
      value = defaultValue;
    }
    this.dropdownEleRefsList(dropdownEleRefs, formControlName, renderer2, value)
  }
  dropdownEleRefsList(dropdownEleRefs: QueryList<ElementRef>, formControlName: string, renderer2: Renderer2, value: string | boolean) {
    for (const nativeEle of dropdownEleRefs['_results']) {
      // Assigning mapped value to corresponding dropdown
      if (
        nativeEle.nativeElement.attributes.formcontrolname.value ===
        formControlName
      ) {
        if (nativeEle.nativeElement.nodeName === 'SELECT') {
          this.dropdownClassAddRemove(renderer2, nativeEle, value)
        }
      }
    }
  }
  dropdownClassAddRemove(renderer2: Renderer2, nativeEle: any, value: string | boolean) {
    let parent = renderer2.parentNode(nativeEle.nativeElement);
    let dropdownEle = parent.querySelector('.dropdown');
    if (dropdownEle) {
      let buttonEle = dropdownEle.querySelector('button');
      let liEle = dropdownEle.querySelectorAll('ul > li');
      for (const ele of liEle) {
        if (ele.ariaSelected) {
          renderer2.removeClass(ele, 'aria-selected');
          renderer2.setAttribute(ele, 'aria-selected', 'false');
        }
        if (ele.innerHTML === value) {
          renderer2.addClass(ele, 'aria-selected');
          renderer2.setAttribute(ele, 'aria-selected', 'true');
        }
      }
      renderer2.setProperty(buttonEle, 'innerHTML', value);
    }
  }
  fieldLengthNotEqual(renderer2: Renderer2, nativeEle: any) {
    if (nativeEle.nativeElement.nodeName === 'SELECT') {
      let parent = renderer2.parentNode(nativeEle.nativeElement);
      let dropdownEle = parent.querySelector('.dropdown');
      if (dropdownEle) {
        let buttonEle = dropdownEle.querySelector('button');
        renderer2.setAttribute(buttonEle, 'disabled', 'true');
      }
    }
  }
  fieldLengthisEqual(renderer2: Renderer2, nativeEle: any) {
    if (nativeEle.nativeElement.nodeName === 'SELECT') {
      let parent = renderer2.parentNode(nativeEle.nativeElement);
      let dropdownEle = parent.querySelector('.dropdown');
      if (dropdownEle) {
        let buttonEle = dropdownEle.querySelector('button');
        renderer2.removeAttribute(buttonEle, 'disabled');
      }
    }
  }
  isNativeEleAttribute(renderer2: Renderer2, nativeEle: any) {
    if (
      nativeEle.nativeElement.attributes.getNamedItem('disabled') &&
      nativeEle.nativeElement.attributes.getNamedItem('disabled')
        .value === 'true'
    ) {
      let parent = renderer2.parentNode(nativeEle.nativeElement);
      let dropdownEle = parent.querySelector('.dropdown');
      if (dropdownEle) {
        let buttonEle = dropdownEle.querySelector('button');
        renderer2.setAttribute(buttonEle, 'disabled', 'true');
      }
    } else if (
      nativeEle.nativeElement.attributes.getNamedItem('disabled') &&
      nativeEle.nativeElement.attributes.getNamedItem('disabled')
        .value === 'false'
    ) {
      let parent = renderer2.parentNode(nativeEle.nativeElement);
      let dropdownEle = parent.querySelector('.dropdown');
      if (dropdownEle) {
        let buttonEle = dropdownEle.querySelector('button');
        renderer2.removeAttribute(buttonEle, 'disabled');
      }
    }
  }
  fieldDisableFunction(fields: FieldsRef, dropdownEleRefs: QueryList<ElementRef>, formGroup: FormGroup, renderer2: Renderer2) {
    let fieldsToCheckCurrentValues = [];
    for (const i in fields.fieldsToCheck) {
      if (
        formGroup.value[fields.fieldsToCheck[i]] &&
        formGroup.value[fields.fieldsToCheck[i]] !== ''
      ) {
        fieldsToCheckCurrentValues.push(
          formGroup.value[fields.fieldsToCheck[i]]
        );
      } else if (
        formGroup.value[fields.fieldsToCheck[i]] &&
        formGroup.value[fields.fieldsToCheck[i]] === ''
      ) {
        fieldsToCheckCurrentValues = [];
      }
    }
    for (const nativeEle of dropdownEleRefs['_results']) {
      // Checking if fieldsToCheck length matches with the values found from formGroup above
      if (
        nativeEle.nativeElement.attributes.formcontrolname.value ===
        fields.fieldToDisable &&
        fieldsToCheckCurrentValues.length !== fields.fieldsToCheck.length
      ) {
        this.fieldLengthNotEqual(renderer2, nativeEle)
      } else if (
        nativeEle.nativeElement.attributes.formcontrolname.value ===
        fields.fieldToDisable &&
        fieldsToCheckCurrentValues.length === fields.fieldsToCheck.length
      ) {
        this.fieldLengthisEqual(renderer2, nativeEle)
        // below condition for disabling all npSelect element's which has disabled true attribute added like [attr.disabled]="true" even if we are passing fields argument
      } else if (nativeEle.nativeElement.attributes) {
        this.isNativeEleAttribute(renderer2, nativeEle);
      }
    }
  }
  isDropdownFieldDisabled(dropdownEleRefs: QueryList<ElementRef>, renderer2: Renderer2, formGroup: FormGroup, fields?: FieldsRef) {
    if (fields?.fieldToDisable) {
      this.fieldDisableFunction(fields, dropdownEleRefs, formGroup, renderer2)
    } else {
      for (const nativeEle of dropdownEleRefs['_results']) {
        // below condition for disabling all npSelect element's which has disabled true attribute added like [attr.disabled]="somebooleanproperty" if we are not passing fields argument
        if (nativeEle.nativeElement.attributes) {
          this.defaultFieldDisable(nativeEle, renderer2)
        }
      }
    }
  }
  itemsToDisable(renderer2: Renderer2, nativeEle: any) {
    let parent = renderer2.parentNode(nativeEle.nativeElement);
    let dropdownEle = parent.querySelector('.dropdown');
    if (dropdownEle) {
      let buttonEle = dropdownEle.querySelector('button');
      renderer2.setAttribute(buttonEle, 'disabled', 'true');
    }
  }
  itemsToDisableFalse(renderer2: Renderer2, nativeEle: any) {
    let parent = renderer2.parentNode(nativeEle.nativeElement);
    let dropdownEle = parent.querySelector('.dropdown');
    if (dropdownEle) {
      let buttonEle = dropdownEle.querySelector('button');
      renderer2.removeAttribute(buttonEle, 'disabled');
    }
  }
  defaultFieldDisable(nativeEle: any, renderer2: Renderer2) {
    if (
      nativeEle.nativeElement.attributes.getNamedItem('disabled') &&
      nativeEle.nativeElement.attributes.getNamedItem('disabled')
        .value === 'true'
    ) {
      this.itemsToDisable(renderer2, nativeEle)
    } else if (
      nativeEle.nativeElement.attributes.getNamedItem('disabled') &&
      nativeEle.nativeElement.attributes.getNamedItem('disabled')
        .value === 'false'
    ) {
      this.itemsToDisableFalse(renderer2, nativeEle)
    }
  }

  downloadCase(caseDetail: any) {
    this.audTokenSr.setIsDownload(true);
    const fileName = caseDetail?.caseName + '.xlsx';
    this.casemanagementservice.downloadCase(caseDetail.id)
    .subscribe(this.handleDownloadSubscription(fileName, 'application/octet-stream'));
  }

  downloadCaseJson(caseDetail: any){
    this.audTokenSr.setIsDownload(true);
    const fileName = caseDetail?.caseName + '.json';
    this.casemanagementservice.downloadCaseJson(caseDetail.id)
      .subscribe(this.handleDownloadSubscription(fileName, 'application/octet-stream'));
  }

  downloadOptimizedCaseVisuals(caseDetail: any){
    this.audTokenSr.setIsDownload(true);
    const fileName = `${caseDetail?.caseName} - Network Visuals.zip`;
    this.casemanagementservice.downloadOptimizedCaseVisuals(caseDetail.id)
      .subscribe(this.handleDownloadSubscription(fileName, 'application/zip', 'Network visuals are not available for this case.'));
  }

  private handleDownloadSubscription(fileName: string, fileType: string, customErrorMsg: string = ''): Partial<Observer<any>> | undefined {
    return {
      next: (response: BlobPart) => {
        const blob = new Blob([response], { type: fileType });
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        link.download = fileName;
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
        this.toastSer.setToastNotification({ show: true, type: 'success', msg: `Downloading` });
      },
      error: (err: any) => {
        const msg = err.status === 404 ? customErrorMsg : `Request couldn't be completed, try again in sometime.`;
        this.toastSer.setToastNotification({ show: true, type: 'error', msg });
      },
      complete: () => {
        this.audTokenSr.setIsDownload(false);
      }
    }
  }

  getCaseStatus(statusvalue: string): string {
    const statusFound = caseStatus[statusvalue as keyof typeof caseStatus];
    const status = statusFound ?? 'Not Found';
    return status;
  }

  getStatusClass(paramsdata: any, isCaseDetailPage = false): string {
    if (isCaseDetailPage) {
      // For case detail page template case status
      switch (paramsdata.status) {
        case 0: //Pending
          return StatusClass.Pending;
        case 1: //Invalid
          return StatusClass.Invalid;
        case 2: //Running
          return StatusClass.Running;
        case 3: //Optimized
          return StatusClass.Optimized;
        case 4: //Failed
          return StatusClass.Failed;
        case 5: //Optimized with Warnings
          return StatusClass.Warning;
        default:
          return StatusClass.NotFound;
      }
    } else {
      // For ag-grid case status
      switch (paramsdata.status) {
        case 0: //Pending
          return StatusClass.Pending_Grid;
        case 1: //Invalid
          return StatusClass.Invalid_Grid;
        case 2: //Running
          return StatusClass.Running_Grid;
        case 3: //Optimized
          return StatusClass.Optimized_Grid;
        case 4: //Failed
          return StatusClass.Failed_Grid;
        case 5: //Optimized with Warnings
          return StatusClass.Warning_Grid;
        default:
          return StatusClass.NotFound_Grid;
      }
    }
  }

  saveReportsRefreshStatus(refreshStatusDetail: RefreshStatusDetail): void {
    if (sessionStorage.getItem('reportsRefreshStatus')) {
      sessionStorage.removeItem('reportsRefreshStatus');
    }
    sessionStorage.setItem(
      'reportsRefreshStatus',
      JSON.stringify(refreshStatusDetail)
    );
  }

  getReportsRefreshStatus(): null | RefreshStatusDetail {
    let refreshStatusDetail: RefreshStatusDetail;
    if (sessionStorage.getItem('reportsRefreshStatus')) {
      refreshStatusDetail = JSON.parse(
        sessionStorage.getItem('reportsRefreshStatus')!
      );
      return refreshStatusDetail;
    } else {
      return null;
    }
  }

  removeReportsRefreshStatus(): void {
    if (sessionStorage.getItem('reportsRefreshStatus')) {
      sessionStorage.removeItem('reportsRefreshStatus');
    }
  }

  //Set Time Zone
  setUtcTimeZone(month: any, days: any, year: any) {
    let dt = new Date(year, month - 1, days);
    return dt.toLocaleString('en-CA', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  }

  
  setUtcTimeZoneFromDate(date: any) {
    let dt = new Date(date).toLocaleString('en-CA', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      timeZone: 'UTC'
    });
    return dt;
  }

  checkCurrentValidationError(params: any, currentValidationError: any) {
    if (params?.data && currentValidationError) {
      return params.data?.id === currentValidationError.id;
    }
    return false;
  }

  highlightErrorColumn(errorItem: any, errorRow: any, gridApi: GridApi, needsRowExpand?: boolean) {
    gridApi.redrawRows();
    gridApi.forEachNode(node => {
      if (needsRowExpand && node.key === errorRow?.monthGrouping) {
        gridApi.setRowNodeExpanded(node, true);
      }
      if (node?.data?.id === errorItem.id) {
        setTimeout(() => {
          gridApi.ensureColumnVisible(errorItem.propertyName, 'middle');
          gridApi.ensureIndexVisible(node.rowIndex!, 'middle');
          gridApi.stopEditing();
          gridApi.startEditingCell({ 
            rowIndex: node.rowIndex!,
            colKey: errorItem.propertyName
          });
        }, 100);
      }
    });
  }

  checkValidationErrorFixed(params: any, currentValidationError: any) {
    if (params.data && currentValidationError) {
      if (params.data.id === currentValidationError.id && params.column.colId === currentValidationError.propertyName) {
        return true;
      }
    }
    return false;
  }

  // pass tab name or local storage key name as the argument
  resetLayoutChanges(tabName: string) {
    const updatedState = this.storageService.retriveColumnState(tabName);
    // check if the updated state is present in local storage
    if (updatedState !== '') {
      this.storageService.removeColumnState(tabName);
    }
  }
}
