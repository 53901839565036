import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CaseDetail, ModalEvent } from 'src/app/shared/interface/case-managment.interface';
import { IsLoadingService } from 'src/app/shared/services/is-loading.service';
import { ReportPBI } from '../../../../shared/models/reports';


@Component({
  selector: 'reports-modal',
  templateUrl: './reports-modal.component.html',
  styleUrls: ['./reports-modal.component.scss']
})
export class ReportsModalComponent implements OnInit {
  @Input() closeModal!: ModalEvent;
  @Input() selectedCaseDetail!: CaseDetail | null;
  @Input() parentCaseDetails!: any;
  @Input() selectedCases!: any;
  @Output() closePopup = new EventEmitter();
  reports: any[] = [];
  report: ReportPBI = new ReportPBI();
  selectedReport: string;
  list :any;

  constructor(
    private isLoadingSr: IsLoadingService,
    private router: Router) {
    this.reports = this.report.ReportsList;
    this.list  =  this.reports
    this.selectedReport = this.reports[0];
  }

  ngOnInit(): void {
    this.disableRadio();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.disableRadio();
  }

  disableRadio() {
     this.reports.forEach((ele: any) => {
      if (ele.name.toLowerCase() === 'case comparison'  && this.selectedCases.length > 1) {
        ele.isEnable = false;
      }
      else if (ele.name.toLowerCase() !== 'case comparison'  && this.selectedCases.length > 1) {
        ele.isEnable = true;
      }
      else {
        ele.isEnable = false;
      }
    });
  }

  generate() {
    this.isLoadingSr.setLoading(true);

    this.router.navigate(['nglo/report/selectedCases'], {
      state: { selectedCases: this.selectedCases, selectedReport: this.selectedReport }
    });
    this.isLoadingSr.setLoading(false);

  }

  close() {

    this.closePopup.emit(false);
  }


}
