<div class="landing-page select-container">
    <div class="dashboard">
        <h2>welcome to <span class="uppercase">NGL</span> optimizer dashboard</h2>
        <p>The <span class="uppercase">NGL</span> optimizer is targeted towards Maiximizing returns for the chevron's Global <span class="uppercase">NGL</span>
        export portfolio for the US gulf, West Africa, Europe,the Middle East & South America over a 90-day time horizon.The optimizer accounts for regional arbs,
    shipping cost, current commercial agreements,operational constraints, etc.The optimizer will provide several 'case' solutions based on economics and schedule complexity.</p>
    </div>

    <section class="content">
<div class="tab-center">
    <div class="ag-grid position-relative">
        <np-tabbed class="">
            <div class="tab-content" role="tabpanel" aria-hidden="false">
            <ul class="d-flex">
                <li class="publishIcon"  (click)="navigatePage('/nglo/publishedCases')">
                    <span class="icon"> <img class="open" src="../../assets/icons/24_History.svg" class="load-snapshot-icon" alt="History 24_History"></span>
                    <div>Published Cases</div>
                    <p>visualize planned & in transit voyages, out & in charters and optionality within the market for the next 90 days</p>
                </li>
                <li  (click)="navigatePage('/nglo/caseManagement')">
                    <span class="icon"> <img class="open" src="../../assets/icons/16_Circle_Checkmark.svg" class="load-snapshot-icon" alt="History 24_History"></span>
                    <div>Case Management</div>
                    <p>visualize, replicate, delete or make a new planning scenario</p>
                </li>
                <li  (click)="navigatePage('/nglo/referenceData')">
                    <span class="icon"> <img class="open" src="../../assets/icons/16_Cloud-blue.svg" class="load-snapshot-icon" alt="History 24_History"></span>
                    <div>Master Data</div>
                    <p>visualize all the global reference data consumed by application to run and optimize planning scenarios.</p>
                </li>
                <li  (click)="navigatePage('/nglo/pricingData')">
                    <span class="icon"> <img class="open" src="../../assets/icons/16_Refresh.svg" class="load-snapshot-icon" alt="History 24_History"></span>
                    <div>Zema Pricing</div>
                    <p>visualize the latest curve prices for the curves consumed by the application to run and optimize planning scenarios.</p>
                </li>
            </ul>
            </div>
        </np-tabbed>
    </div>
</div>
    </section>
</div>

<div class="home-footer align-items-center sub-header-btns">
    <button class = "help-button" (click) = "navigateToHelp()">Help</button> 
    <div>© 2001 - {{year}} Chevron Corporation</div>
</div>