import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fieldName'
})
export class FieldNamePipe implements PipeTransform {

  transform(fieldName: string): string {
    fieldName = fieldName.trim();
    fieldName = fieldName.split(/(?=[A-Z])/).join(' ').toLowerCase();
    return fieldName;
  }
}
