import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tabName'
})
export class TabNamePipe implements PipeTransform {
  replacements: { [key:string]: string } = {
    'inCharter': 'in charter schedule',
    'outCharter': 'out charter schedule',
    'supplyDemand': 'supply/demand'
  };

  transform(tabName: string): string {
    tabName = tabName.trim();
    tabName = this.replacements[tabName] || tabName;
    tabName = tabName.split(/(?=[A-Z])/).join(' ').toLowerCase();
    return tabName;
  }

}
