import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AudTokenService {
  private isDownload = false;
  setAudToken(tokenStr: any) {
    localStorage.removeItem('access_token');
    localStorage.setItem("access_token", tokenStr.toString());
  }

  setIsDownload(isDownload: boolean) {
    this.isDownload = isDownload;
  }

  getIsDownload(): boolean {
    return this.isDownload;
  }
}
