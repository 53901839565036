import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants, Titles } from '../../shared/constants/constants';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-nglo-home',
  templateUrl: './nglo-home.component.html',
  styleUrls: ['./nglo-home.component.scss']
})
export class NgloHomeComponent implements OnInit {

  pageName:string ='/publishedCases';
  year: number = 0;

  constructor(
    private route: Router,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(Titles.getNgloTitle(Titles.HOME));
    this.year = new Date().getFullYear();
  }

  getPageName(url:any){
    this.pageName = url;
  }
  navigatePage(url:any){
    this.route.navigate([url]);
  }
  navigateToHelp() {
    window.open(Constants.HELP_DOCUMENTATION_LINK, "_blank");
  }
}

