import {
  Component,
  Input,
  forwardRef,
  HostListener,
  ElementRef,
  Output,
  EventEmitter,
  ViewChild,
  ViewChildren,
  QueryList
} from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
@Component({
  selector: "app-select-dropdown",
  templateUrl: "select-dropdown.component.html",
  styleUrls: ["select-dropdown.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDropdownComponent),
      multi: true
    }
  ]
})
export class SelectDropdownComponent implements ControlValueAccessor {
  list = [];
  temp_list = [];
  keyword = "";
  selectedOptions:any = []

  @Output() afterChange = new EventEmitter();
  @ViewChild("input", { static: false }) input!: ElementRef;
  @ViewChildren("checkboxes") checkboxes!: QueryList<ElementRef>;
  @Input("items") set items(value:any) {
    this.list = value;
    this.temp_list = value;
  }
  @Input("disabled") disabledStatus: any;
  @Input("defaultValue") defaultValue: any;
  @Input("multiple") multiple: any;
  onChange: any = () => { };
  onTouch: any = () => { };
  value: any = "select";
  shown = false;
  constructor(private ele: ElementRef) {
  }
  ngOnChanges() {
    if(this.defaultValue){
      this.select(this.defaultValue)
    }
    else{
      this.value = 'Select'
    }
    if(this.defaultValue && !this.disabledStatus){
      this.disabledStatus = false;
    }
  }
  writeValue(value: any) {
    return ;
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
  search(e: string) {
    const val = e.length  ? e.toLowerCase() : "";
    const temp = this.temp_list.filter((x:any) => {
      x = String(x)
      if ( x.length  && x.toLowerCase().indexOf(val) !== -1 || !val) {
        return x;
      }
    });
    this.list = temp;
  }
  select(item:string) {
    this.value = item;
    this.shown = false;
    this.keyword = '';
    this.search('');

    if(item === 'select'){
      this.value = "Select"
      this.shown = false;
      item = "";
    }
    if(!this.multiple){
      this.onChange(item);
      this.afterChange?.emit(item);
    }
    }
  show() {
    this.shown = this.shown ? false : true;
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 200);
  }
  resetInput(){
    this.keyword = '';
    this.selectedOptions = [];
    this.uncheckAll();
    this.value = "select";
  }
  closeDropdown(){
    this.shown = false;
  }
  uncheckAll() {
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
    this.selectedOptions = [];
    this.onChange(this.selectedOptions.toString());
    this.afterChange.emit("");
  }

  changeSelection(event:any){
    let value = event.target.value;
    if(!this.selectedOptions.includes(value)){
      this.selectedOptions.push(value);
    }
    else{
        const index = this.selectedOptions.indexOf(value);
        if (index > -1) {
          this.selectedOptions.splice(index,1);
        }
    } 
    
    if(this.selectedOptions.length > 0){
      this.value = this.selectedOptions.join("#")
      this.onChange(this.value);
      this.afterChange.emit();
    }
    else{
      this.onChange(this.selectedOptions.toString());
      this.afterChange.emit("");
    }
  }
  @HostListener("document:click", ["$event"]) onClick(e: { target: any; }) {
    if (!this.ele.nativeElement.contains(e.target)) {
      this.shown = false;
    }
  }
}
