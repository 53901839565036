import { Directive, Host, Optional, ElementRef, Attribute, Renderer2 } from '@angular/core';
import { NgControl, FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[formControlNam], [formControl]',
})
export class ErrorMessagesDirective {
  private errorElement!: HTMLElement;

  constructor(
    private control: NgControl,
    private host: ElementRef<HTMLElement>,
    @Attribute('errorFor') private type: string,
    private renderer: Renderer2,
    @Optional() @Host() private form: FormGroupDirective
  ) {
    this.type = this.type || 'input';
  }

  private append() {
    if (this.errorElement) {
      this.errorElement.style.display = 'block';
    } else {
      this.errorElement = document.createElement('div');
      this.errorElement.classList.add('error-block');
      let container:any = this.element;

      container.parentNode.insertBefore(
        this.errorElement,
        container.nextSibling
      );
    }
    this.errorElement.innerText = resolveError(this.control.errors);
  }

  private hide() {
    if(this.errorElement)
    this.errorElement.style.display = 'none';
  }


  get element() {
    return this.host.nativeElement;
  }
}

function resolveError(errors:any) {
  const errorType = Object.keys(errors)[0];

  switch (errorType) {
    case 'required':
      return 'This field is required';
    case 'email':
      return 'This is not a valid email';
    case 'minlength':
      return `Min length is ${errors.minlength.requiredLength}`;
      default: return '';
  }
}
