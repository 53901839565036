import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-np-header',
  templateUrl: './np-header.component.html',
  styleUrls: ['./np-header.component.scss']
})
export class NpHeaderComponent{
  @Input() siteName: string = '';
}
