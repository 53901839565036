<div class="search-dropdown {{disabledStatus}}" [ngClass]="{'open':shown && list !== undefined}">
  <div class="search-dropdown__btn" (click)="show()">
       <span class="updated-value selectedOptions"> {{selectedOptions.join(',')}}</span>
        <div *ngIf="multiple">
          <span class="updated-value default-option" *ngIf="selectedOptions.length === 0">Select</span> 
        </div>
        <div *ngIf="!multiple">
          <span class="updated-value default-option" [ngClass]="{'hasValue': value !== 'Select'}">{{value}}</span>
        </div>
    </div>
    <div class="search-dropdown__dropdown">
      <input class="search-dropdown__dropdown__search" type="text" placeholder="Search" [(ngModel)]="keyword"
        (keyup)="search(keyword)" #input />
      <div class="search-dropdown__dropdown__scroller">
        <div class="search-dropdown__dropdown__item" [hidden]="keyword.length > 0">
          <span (click)="select('select')" class="flex-grow-1"> Select </span>
          <div *ngIf="multiple">
            <span class="close-btn icon-remove" title="close" (click)="closeDropdown()"></span>
            <img src="../../../../assets/icons/16_Trashcan.svg" title="delete selection" alt="delete icon"  (click)="uncheckAll()" class="clear-icon close-btn">
          </div>
         </div>
        <div class="search-dropdown__dropdown__item" *ngFor="let item of list; let i = index;">
          <div *ngIf="multiple" class="flex-grow-1">
            <label for="{{item}}-{{i}}" class="dropdown-inner-item d-flex align-items-center"> 
              <input type="checkbox" #checkboxes id="{{item}}-{{i}}"  class="option-select-checkbox mr-2" (change)="changeSelection($event)" value="{{item}}">
               {{item}}</label>  
          </div>
          <span *ngIf="!multiple" class="flex-grow-1" (click)="select(item)">{{item}}</span>
        </div>
        <div class="search-dropdown__dropdown__msg" *ngIf="list?.length == 0">No Result Found</div>
      </div>
    </div>
  </div>