import { Component } from '@angular/core';

@Component({
  selector: 'app-nglo',
  templateUrl: './nglo.component.html',
  styleUrl: './nglo.component.scss'
})
export class NgloComponent {
  isNGLO = true;
}
