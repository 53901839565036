export class Constants {
    // keep only static readonly class variables, used for NGLO constants
    // tab names
    static readonly BASE_PLAN_TAB = 'basePlan';
    static readonly CHARTERING_TAB = 'chartering';
    static readonly IN_CHARTER_SCHEDULE_TAB = 'inCharter';
    static readonly OUT_CHARTER_SCHEDULE_TAB = 'outCharter';
    static readonly PRICING_TAB = 'pricing';
    static readonly ZEMA_PRICING_TAB = 'zemaPricing';
    static readonly PRICING_CALCULATOR_TAB = 'pricingCalculator';
    static readonly ASIA_NETBACK_CALCULATOR_TAB = 'asiaNetbackCalculator';
    static readonly VESSEL_DETAILS_TAB = 'vesselDetails';
    static readonly VESSEL_INFO_TAB = 'vesselInfo';
    static readonly SUPPLY_DEMAND_TAB = 'supplyDemand';
    static readonly GANTT_CHART_TAB = 'ganttChart';
    static readonly TABULAR_VIEW = 'tabular';
    static readonly TRADER_REPORTS = "traderReports";
    static readonly UNKNOWN = 'Unknown';
    
    static readonly SRA_TAB = 'sra';
    static readonly OPTIONALITY_TAB = 'optionality';
    static readonly CANAL_SCHEDULER = 'canalScheduler';
    static readonly AUCTION_SLOT_PRICING = 'auctionSlotPricing';

    // constant values
    static readonly FULL_CARGO_DISCOUNT = 4;

    // base plan view
    static readonly DEFAULT_VIEW = 'default';
    static readonly FLAT_VIEW = 'flatView';
    static readonly LOAD_MONTH_VIEW = 'loadMonth';
    static readonly TRADE_DIRECTION_VIEW = 'tradeDirectionView';
    static readonly DISCHARGE_MONTH_VIEW = 'dischargeMonth';

    static readonly OUT_CHARTER = 'outcharter';
    static readonly OUT_CHARTER_SCHEDULE = 'outcharterschedule';
    static readonly LOAD = 'load';
    static readonly DISCHARGE = 'discharge';
    static readonly SRA = 'sra';
    static readonly OPTIONALITY_PURCHASE = "Optionality Purchase";
    static readonly OPTIONALITY_DEMAND = "Optionality Demand";

    // shared function names
    static readonly FN_OPTIMIZE_CASE = 'optimizeCase';
    static readonly FN_PIVOTED_BASE_PLAN = 'pivotedBasePlan';
    static readonly FN_DUPLICATE_ROWS = 'duplicateRows';
    static readonly FN_DELETE_SELECTED_ROWS = 'deleteSelectedRows';
    static readonly FN_SYNC = 'sync';
    static readonly FN_HIGHLIGHT_ERROR_COLUMN = 'highlightErrorColumn';
    static readonly FN_RESET_GRID_CHANGES = 'resetGridChanges';

    // case status
    static readonly STATUS_RUNNING = 'Running';
    static readonly STATUS_OPTIMIZED = 'Optimized';
    
    // UI validations
    static readonly STATUS_INVALID = 'INVALID';

    // formats
    static readonly DATE_FORMAT = 'yyyy-MM-dd';
    static readonly DATE_FORMAT_2 = 'MMM-dd-yyyy';
    static readonly LONG_DATE_FORMAT = 'MMM-dd-yyyy hh:mm:ss';
    static readonly GANTT_CHART_DATE_FORMAT = 'dd-MMM-yy';

    //Pricing Calc
    static readonly PORT_HOUSTON = "Houston Tx";

    // tab mappings (used for correctly selecting tabs for validation errors)
    static readonly VALIDATION_TAB_MAPPING = [
        {
            dataTab: 'casePlan', // refers to the name used by backend validation
            uiTab: this.BASE_PLAN_TAB, // corresponding name used in the UI tab
            tabName: 'base plan', // name displayed in the UI
            parentTab: this.BASE_PLAN_TAB // parent tab name when the dataTab in nested
        },
        {
            dataTab: 'inCharterSchedule',
            uiTab: this.IN_CHARTER_SCHEDULE_TAB,
            tabName: 'in charter schedule',
            parentTab: this.CHARTERING_TAB
        },
        {
            dataTab: 'outCharterSchedule',
            uiTab: this.OUT_CHARTER_SCHEDULE_TAB,
            tabName: 'out charter schedule',
            parentTab: this.CHARTERING_TAB
        },
        {
            dataTab: this.VESSEL_INFO_TAB,
            uiTab: this.VESSEL_INFO_TAB,
            tabName: 'vessel info',
            parentTab: this.VESSEL_DETAILS_TAB
        },
        {
            dataTab: this.SUPPLY_DEMAND_TAB,
            uiTab: this.SUPPLY_DEMAND_TAB,
            tabName: 'supply/demand',
        }
    ];

    //Documentation Links
    static readonly HELP_DOCUMENTATION_LINK = 'https://chevron.sharepoint.com/:b:/r/sites/NGLOptimizationProject/Shared%20Documents/General/Support%20and%20Transition/Documentation%20Archive/NGLO%20Documentation%20v1.2.pdf?csf=1&web=1&e=4vNTzw';

    //Reference Data Filters
    static readonly TIME_CHARTERED = "Time Chartered";
}

export class Titles {
    static readonly NGLO = "NGLO";
    static readonly HOME = "Home";
    static readonly PUBLISHED_CASES = "Publishes Cases";
    static readonly CASE_MANAGEMENT = "Case Management";
    static readonly CANAL_SCHEDULE = "Canal Schedule";
    static readonly MASTER_DATA = "Master Data";
    static readonly ZEMA_PRICING = "Zema Pricing";
    static readonly SWAGGER = "Swagger API Endpoints";
    static readonly NOT_FOUND = "Not Found";

    static readonly CMO = "CMO";
    static readonly CMO_EXPANDED = "Commodity Movement Optimizers"

    static readonly getNgloTitle = (...title: string[]) => `${this.NGLO} - ${title.join(' - ')}`;
    static readonly getCmoTitle = (...title: string[]) => `${this.CMO} - ${title.join(' - ')}`;
}

export class Config {
    static readonly SWAGGER_ENABLED_ENVIRONMENTS = [ 'dev', 'local' ];
}