import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Titles } from '../shared/constants/constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cmo-home',
  templateUrl: './cmo-home.component.html',
  styleUrl: './cmo-home.component.scss'
})

export class CMOHomeComponent implements OnInit {
  isNGLO = false;
  
  constructor(
    private router: Router,
    private titleService: Title
  ){}

  ngOnInit() {
    this.titleService.setTitle(Titles.getCmoTitle(Titles.CMO_EXPANDED));
    
  }
  
  ngloRouting(){
    this.router.navigate([`/nglo`]);
  }

  csoRouting(){
    if(environment.environmentName === "local") {
      window.open(`https://stpom-dev-cvx.azurewebsites.net`, "_self");
    }
    else{
      window.open(`https://stpom-${environment.environmentName}-cvx.azurewebsites.net`, "_self");
    }
  }

}