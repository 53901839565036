import { DatePipe } from '@angular/common';
import { Directive, ElementRef, HostListener, Input, Renderer2} from '@angular/core';
@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective {
  private tooltip!:any; 
  @Input('date') date: Date | undefined = new Date();
  constructor(private elementRef: ElementRef, private renderer: Renderer2, private datePipe: DatePipe) { 
  }
  @HostListener('mouseover') onMouseOver() {
    // create the tooltip and add it to the view hierarchy
    this.tooltip = this.renderer.createElement('div');
    const text = this.date ? this.datePipe.transform(this.date, 'medium') : 'not available';
    const textContent = this.renderer.createText(`last refreshed: ${text}`);
    this.renderer.appendChild(this.tooltip, textContent);
    this.renderer.addClass(this.tooltip, 'custom-tooltip');
    this.renderer.setStyle(this.tooltip, 'position', 'absolute');
    this.renderer.appendChild(this.elementRef.nativeElement, this.tooltip);
  }
  @HostListener('mouseout') onMouseOut() {
    // destroy the tooltip and remove it from the view hierarchy
    this.renderer.removeChild(this.elementRef.nativeElement, this.tooltip)
  }

}
