import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'np-modal',
  templateUrl: './np-modal.component.html',
  styleUrls: ['./np-modal.component.scss']
})
export class NpModalComponent implements OnInit {
  @Input() show: boolean = false;
  @Input() noModalButtons: boolean = false;
  @Input() label: string = '';
  @Input() headerTitle: string = '';
  @Input() subHeaderText: string = '';
  @Input() primaryButtonLabel: string = '';
  @Input() secondaryButtonLabel: string = '';
  @Output() onPrimaryClick= new EventEmitter();
  @Output() onSecondaryClick = new EventEmitter();
  @Output() onCloseClick = new EventEmitter();

  ngOnInit(): void {
    document.querySelector('body')?.classList.add('modal-open');
  }

  ngOnDestroy() {
    document.querySelector('body')?.classList.remove('modal-open');
  }
  primaryClick() {
    this.onPrimaryClick.emit();
  }
  secondaryClick() {
    this.onSecondaryClick.emit();
  }
  closeModal() {
    this.onCloseClick.emit();
  }
}
