<div class="homeLayout row pb-1 pr-2">
    <div class = "col-md-6">
        <label class="optimizerLabel">Network Optimization</label>
        <button class = "optimizerButton" (click) = "csoRouting()"> Commodity Supply Optimizer (CSO) </button>
        <div class = "optimizerDescription">CSO is a cloud-based PaaS model that delivers a Supply & Sales plan maximizing the value chain profitability of crude, natural gas (NG) and natural gas liquids (NGLs) from Upstream production through to the end consumer. The model empowers users to make rapid decisions in time-sensitive situations by offering centralized user-friendly analytical and reporting tools to conduct more in-depth data driven analysis.

        </div>
    </div>
    <div class = "col-md-6">
        <label class="optimizerLabel">Vessel Schedule Optimization</label>
        <button class = "optimizerButton" (click) = "ngloRouting()"> Natural Gas Liquid Optimizer (NGLO)</button>
        <div class = "optimizerDescription">The NGL optimizer is targeted towards Maiximizing returns for the chevron's Global NGL export portfolio for the US gulf, West Africa, Europe, the Middle East & South America over a 90-day time horizon. The optimizer accounts for regional arbs, shipping cost, current commercial agreements, operational constraints, etc. The optimizer will provide several 'case' solutions based on economics and schedule complexity.</div>
    </div>
</div>