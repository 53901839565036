import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'np-notification',
  templateUrl: './np-notification.component.html',
  styleUrls: ['./np-notification.component.scss']
})
export class NpNotificationComponent implements OnChanges {
  @Input() data: any = {};
  @Input() show:boolean = false;
  timerRef: any;
  constructor(private toastSr: ToastService) {
   }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.show && this.data['type']!=='info'){
      this.timerRef = setTimeout(()=>this.hide(),5000);
    } else{
      this.timerRef = setTimeout(()=>this.hide(),50000);
    }
  }
  
  hide(){
    this.show = false;
    clearTimeout(this.timerRef);
    this.toastSr.setToastNotification({show: false, msg: '', type:''});
  }
}
