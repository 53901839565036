import { environment } from 'src/environments/environment';

export class ReportPBI {
    get ReportsList(): any[] {
        let rpts = [
            this.BasePlan,
            this.VoyageList,
            this.SupplyDemandEconomics,
            this.CaseComparison
        ];
        return rpts;
    }
    get BasePlan(): any { return { name: "Base plan", value: environment.BasePlanSectionId, isEnable: false } }
    get VoyageList(): any { return { name: "Voyage List", value: environment.VoyageListSectionId,  isEnable: false } }
    get SupplyDemandEconomics(): any { return { name: " Supply & Demand Economics ", value: environment.SupplyDemandEconomicsSectionId,  isEnable: false } }
    get CaseComparison(): any { return { name: "Case Comparison", value: environment.CaseComparisonSectionId,  isEnable: false } }
}
