import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ToastService } from './toast.service';
import { IsLoadingService } from './is-loading.service';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor( private http: HttpClient, private toastSer: ToastService, private isLoadingSr: IsLoadingService) { }
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  private header = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'x-action-intent': 'validate'
    }),
  };
  private handleServerError(err: HttpErrorResponse){
    this.isLoadingSr.setLoading(false);
    return throwError(()=> err);
  }
   
  post(path: string, body: object = {}): Observable<any> {
    this.isLoadingSr.setLoading(true);
    return this.http.post(`${path}`, JSON.stringify(body), this.httpOptions)
    .pipe(map((res)=>{
      this.isLoadingSr.setLoading(false);
      return res;
    }),catchError((err: HttpErrorResponse) => {
      return this.handleServerError(err)
    }));
  }

  postHeader(path: string, body: object = {}): Observable<any> {
    this.isLoadingSr.setLoading(true);
    return this.http.post(`${path}`, JSON.stringify(body), this.header)
    .pipe(map((res)=>{
      this.isLoadingSr.setLoading(false);
      return res;
    }),catchError((err: HttpErrorResponse) => {
      return this.handleServerError(err)
    }));
  }
  
  get(path: string, body?: object): Observable<any> {
    this.isLoadingSr.setLoading(true);
    return this.http.get(`${path}`, this.httpOptions)
    .pipe(map((res)=>{
      this.isLoadingSr.setLoading(false);
      return res;
    }),catchError((err: HttpErrorResponse) => {
      return this.handleServerError(err)
    }));
  } 
  
  put(path: string, body: any = {}): Observable<any> {
    this.isLoadingSr.setLoading(true);
    return this.http.put(`${path}`, JSON.stringify(body), this.httpOptions)
    .pipe(map((res)=>{
      this.isLoadingSr.setLoading(false);
      return res;
    }),catchError((err: HttpErrorResponse) => {
      return this.handleServerError(err)
    }));
  }

  delete(path: string, body: any = {}): Observable<any> {
    this.isLoadingSr.setLoading(true);
    const options = {headers: this.httpOptions.headers, body: JSON.stringify(body)};
    return this.http.delete(`${path}`,options)
    .pipe(map((res)=>{
      this.isLoadingSr.setLoading(false);
      return res;
    }),catchError((err: HttpErrorResponse) => {
      return this.handleServerError(err)
    }));
  }
}
