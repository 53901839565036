import { Injectable } from '@angular/core';
import { ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _userProfile!: ICvxClaimsPrincipal;
  private _isReadOnlyUser: boolean = false;
  private _isRegularUser: boolean = false;
  private _isAdminUser: boolean = false;

  public set userProfile(cvxClaimsPrincipal: ICvxClaimsPrincipal) {
    this._userProfile = cvxClaimsPrincipal;
    this.userRoles = this._userProfile.roles;
  }

  public get userName() {
    return this._userProfile.name;
  }

  private set userRoles(roles: string[]) {  
    // the highest user role will take effect if the user has multiple roles
    if (roles.includes(environment.securityAdminGroupName)) {
      this._isAdminUser = true;
    } else if (roles.includes(environment.securityUserGroupName)) {
      this._isRegularUser = true;
    } else if (roles.includes(environment.securityReadOnlyGroupName)) {
      this._isReadOnlyUser = true;
    }
  }

  public get userRoles() {
    return this._userProfile.roles;
  }

  public get isAdminUser() {
    return this._isAdminUser;
  }

  public get isRegularUser() {
    return this._isRegularUser;
  }

  public get isReadOnlyUser() {
    return this._isReadOnlyUser;
  }
}
