import { Injectable, RendererFactory2, Renderer2, OnDestroy, ElementRef} from '@angular/core';
import { Observable, Subject, fromEventPattern } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})

/*
* To use the Service we need to first inject the Service by add "providers: [DraggableService]" into the Componet
* Then create a DraggableService variable in the Contructor
* Also in constuctor make an ElementRef ("startingElement") variable
* Lastly add: 
*     ngOnInit(): void {
*       this.draggableService.runService(this.startingElement);
*     } 
* to run the Service 
*/

export class DraggableService implements OnDestroy{
    //Variables used to move the popups 
    private modalElement!: HTMLElement; //element that is moving
    private topStart!: number;
    private leftStart!: number;
    private isDraggable!: boolean; //Flag which represents if the modal is clicked
    private handleElement!: HTMLElement; //What part of the modal can be click and dragged
    private boundryElement!: HTMLElement; //Boundry of where the element can be dragged to (Webpage)
    private basedElement!: ElementRef; //Element given by component which should be the form


    //Event Listeners and objects to destroy the listeners after use
    private _destroy1$ = new Subject();
    private _destroy2$ = new Subject();
    private _destroy3$ = new Subject();
    private _destroy4$ = new Subject();
    public onClick$!: Observable<MouseEvent>;
    public onRelease$!: Observable<MouseEvent>;
    public onMove$!: Observable<MouseEvent>;
    public onLeave$!: Observable<MouseEvent>;

    //Constructor that setups event listeners using RendererFactory2
    constructor(private rendererFactory2: RendererFactory2) {
        const renderer = this.rendererFactory2.createRenderer(null, null);
    
        this.createOnClickObservable(renderer); // Mouse Click Event
        this.createOnReleaseObservable(renderer); // Mouse Release Event
        this.createOnMoveObservable(renderer); // Mouse Move Event
        this.createOnLeaveObservable(renderer); // Mouse Leave Event 
    }

    //Destroys subscribed elements after use
    ngOnDestroy() {
        this._destroy1$.next(1);
        this._destroy1$.complete();
        this._destroy2$.next(1);
        this._destroy2$.complete();
        this._destroy3$.next(1);
        this._destroy3$.complete();
        this._destroy4$.next(1);
        this._destroy4$.complete();
    }

    //method that is called by the components that give the component HTML
    public runService(element: ElementRef) {
        this.basedElement = element;    
        this.gatherElements(); //using the basedElement we can get our other elements to be used
        
        this.onClick$.subscribe((e: MouseEvent) => {
        this.mouseDown(e) //subscribe/activate to the Mouse Down event
        });
        this.onRelease$.subscribe((e: MouseEvent) => {
        this.mouseUp(e) //subscribe/activate to the Mouse Up event
        });
        this.onMove$.subscribe((e: MouseEvent) => {
        this.mouseMove(e) //subscribe/activate to the Mouse Move event
        });
        this.onLeave$.subscribe((e: MouseEvent) => {
        this.mouseLeave(e) //subscribe/activate to the Mouse Leave event
        });
    }

    //Generates Mouse Down event listener using Renderer2
    private createOnClickObservable(renderer: Renderer2) {
        let removeClickEventListener: () => void;
        const createClickEventListener = (
          handler: (e: MouseEvent) => boolean | void //Where the Event is stored
        ) => {
          removeClickEventListener = renderer.listen(this.handleElement, "mousedown", handler); //1st value: where to listen, 2nd value: what to listen for, 3rd value: what to do
        };
        //Stores the event listener into variable
        this.onClick$ = fromEventPattern<MouseEvent>(createClickEventListener, () =>
          removeClickEventListener()
        ).pipe(takeUntil(this._destroy1$)); //listener up until ngOnDestroy() is activated
    }

    //Generates Mouse Release event listener using Renderer2
    private createOnReleaseObservable(renderer: Renderer2) {
        let removeClickEventListener: () => void;
        const createClickEventListener = (
          handler: (e: MouseEvent) => boolean | void //Where the Event is stored
        ) => {
          removeClickEventListener = renderer.listen(this.handleElement, "mouseup", handler); //1st value: where to listen, 2nd value: what to listen for, 3rd value: what to do
        };
        //Stores the event listener into variable
        this.onRelease$ = fromEventPattern<MouseEvent>(createClickEventListener, () =>
          removeClickEventListener()
        ).pipe(takeUntil(this._destroy2$)); //listener up until ngOnDestroy() is activated
    }

    //Generates Mouse Move event listener using Renderer2
    private createOnMoveObservable(renderer: Renderer2) {
        let removeClickEventListener: () => void;
        const createClickEventListener = (
          handler: (e: MouseEvent) => boolean | void //Where the Event is stored
        ) => {
          removeClickEventListener = renderer.listen(this.boundryElement, "mousemove", handler); //1st value: where to listen, 2nd value: what to listen for, 3rd value: what to do
        };
        //Stores the event listener into variable
        this.onMove$ = fromEventPattern<MouseEvent>(createClickEventListener, () =>
          removeClickEventListener()
        ).pipe(takeUntil(this._destroy3$)); //listener up until ngOnDestroy() is activated
    }

    //Generates Mouse Leave event listener using Renderer2
    private createOnLeaveObservable(renderer: Renderer2) {
        let removeClickEventListener: () => void;
        const createClickEventListener = (
          handler: (e: MouseEvent) => boolean | void //Where the Event is stored
        ) => {
          removeClickEventListener = renderer.listen(this.boundryElement, "mouseleave", handler); //1st value: where to listen, 2nd value: what to listen for, 3rd value: what to do
        };
        //Stores the event listener into variable
        this.onLeave$ = fromEventPattern<MouseEvent>(createClickEventListener, () =>
          removeClickEventListener()
        ).pipe(takeUntil(this._destroy4$)); //listener up until ngOnDestroy() is activated
    }
    
    //Method used to get the variables used in this service
    private gatherElements() {
        
        let element = this.basedElement.nativeElement; //nativeElement turns ElementRef to HTMLElement
        this.modalElement = element.closest('.modal-content'); //get the element with .modal-content class (This element is the popup we want to move)
        //element = this.modalElement.firstChild 
        //if (element === null) { return }  //This commented out section makes it so you can only move the popup by using the .modal-header element 
        //this.handleElement = element
        this.handleElement = this.modalElement; //Allow us to drag anywhere on the modal
        this.handleElement.style.cursor = "move"; //Show draggablity with a move cursor
        
        //Below section gets the parent of the parent of .modal-content element, which is the static backdrop of the np-modal component
        //This cover the page of the web app so it mean a user cant drag the modal outside of this boundry
        element = this.modalElement.parentElement;
        if (element === null) { return }
        element = element.parentElement;
        if (element === null) { return }
        this.boundryElement = element;
    }

    //Handles a Mouse Down event
    private mouseDown(event: MouseEvent) {
       //checks if the click is a right click or if the handle is set
       if (event.button === 2 || !this.handleElement) {
          return;
       }
       //checks if user click a movable part of the element
       if (event.target !== this.handleElement && !this.searchParentNode(<any>event.target, this.handleElement)) {
          return;
       }
       //grab the event target as a HTML Element 
       let element = (event.target as HTMLElement);
       //If the click is on an input ignore (below are the different forms of input we used)
       if (element.tagName === "INPUT" || element.tagName === "TEXTAREA" || element.tagName === "SPAN" || 
       element.tagName === "BUTTON" || element.tagName === "APP-SELECT-DROPDOWN" || element.className === "dropdown-inner-item d-flex align-items-center" ) {
          if(element.className !== "d-flex justify-content-between") { return }
       }
       
      this.isDraggable = true; //toggle draggablity 
      this.topStart = event.clientY - Number(this.modalElement.style.top.replace("px", "")); //get up/down position
      this.leftStart = event.clientX - Number(this.modalElement.style.left.replace("px", "")); // get left/right position
      event.preventDefault();

    }
    //Handles a Mouse Up event
    private mouseUp(event: MouseEvent) {
        this.isDraggable = false; //No longer clicking so stop dragging
    }

    //Handles a Mouse Move event
    private mouseMove(event: MouseEvent){
        if (this.isDraggable) { //Check if we can drag
            this.modalElement.style.top = event.clientY - this.topStart + "px"; //then move up/down position
            this.modalElement.style.left = event.clientX - this.leftStart + "px"; //then move left/right position
        }
    }
    //Handles a Mouse Leave event
    private mouseLeave(event: MouseEvent){
        this.isDraggable = false; //No longer clicking on modal so stop dragging
    }
    
    //This method allows the child elements to be click and drag so if you have child elements the draggability will still work 
    private searchParentNode(element: Node, tag: Node): boolean {
        while (element.parentNode) {
            element = element.parentNode;
            if (element === tag) {
                return true;
            }
        }
        return false;
    }

}