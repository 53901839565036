import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import 'ag-grid-enterprise';
import { NextPage } from '@cvx/nextpage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NpHeaderComponent } from './np-header/np-header.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CalAngularModule } from '@cvx/cal-angular';
import { AuthTokenServiceInterceptor } from './auth-token-service.interceptor';
import { AgGridModule } from 'ag-grid-angular';
import { SharedModule } from './shared/modules/shared.module';
import { NpNotificationComponent } from './shared/components/np-notification/np-notification.component';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { UserService } from './shared/services/user.service';
import { CMOHomeComponent } from './cmo-home/cmo-home.component';
import { NgloComponent } from './nglo/nglo.component';

@NgModule({
  declarations: [
    AppComponent,
    NpHeaderComponent,
    NpNotificationComponent,
    NgloComponent,
    CMOHomeComponent
  ],
  imports: [
    CalAngularModule.forRoot({
      "autoSignIn": true,
      "popupForLogin": false,
      "cacheLocation": "localStorage",
      "instance": "https://login.microsoftonline.com/",
      "tenantId": "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
      // "clientId": "b36d2abd-752b-42ca-bdf4-749154024973",
      // "clientId": "8fea6735-461a-4012-a3aa-e749c4fc0b0d",
      "clientId": environment.clientID,
      // redirectUri will need to match a redirectUri setup in your app registration
      // "redirectUri": "http://localhost:4200",
       "redirectUri": environment.calRedirectURI,
      "oidcScopes": ["openid", "profile", "User.Read","offline_access"],
      "graphScopes": ["User.Read", "User.Read.All"]
    }),
    BrowserModule,
    AppRoutingModule,
    NextPage,
    AgGridModule,
    SharedModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenServiceInterceptor,
      multi: true
    },
    UserService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
