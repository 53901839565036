import { Directive, HostListener, ElementRef, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ValidationMsgService } from '../services/validation-msg.service';

@Directive({
  selector: '[appFormControlValidationMsg]'
})
export class FormControlValidationMsgDirective implements OnInit, OnDestroy {
  errorSpanId: string = '';
  statusChangeSubscription!: Subscription;

  constructor(private elRef: ElementRef,
    private renderer2: Renderer2,
    private control: NgControl,
    private validationMsgService: ValidationMsgService
  ) { }

  ngOnInit(): void {
    //Assigning error span id dynamically instead of fetching it from form controls
    const cryp = window.crypto || window.msCrypto;
    let tab = new Uint32Array(1);
    this.errorSpanId = cryp.getRandomValues(tab).toString();
    this.statusChangeSubscription = this.control.statusChanges!.subscribe(
      (status) => {
        if (status === 'INVALID' && (!this.control.pristine || this.control.touched)) {
          this.showError();
        } else {
          this.removeError();
        }
      }
    )

  }

  ngOnDestroy(): void {
    this.statusChangeSubscription.unsubscribe();
  }

  @HostListener('blur', ["$event"])
  handleBlurEvent(event: any) {
    //This is needed to handle the case of clicking a required field and moving out.
    //Rest all are handled by status change subscription
    if (this.control.value === null || this.control.value === '') {
      if (this.control.errors) this.showError();
      else this.removeError();
    }
  }

  private showError() {
    this.removeError();
    const valErrors: ValidationErrors = this.control.errors!;
    const firstKey = Object.keys(valErrors)[0];
    const errorMsgKey = `${firstKey}`;
    const errorMsg = this.validationMsgService.getValidationMsg(errorMsgKey);
    const errSpan = '<span class="form-validation-msg" id="' + this.errorSpanId + '">' + errorMsg + '</span>';
    this.elRef.nativeElement.parentElement.insertAdjacentHTML('beforeend', errSpan);
    let parent = this.renderer2.parentNode(this.elRef.nativeElement);
    let formControlEle = parent.querySelector('.form-control');
    let dropdownEle = parent.querySelector('.dropdown');
    if (dropdownEle) {
      let buttonEle = dropdownEle.querySelector('button');
      this.renderer2.addClass(dropdownEle, 'form-validation-border');
      this.renderer2.addClass(buttonEle, 'form-validation-label');
    }
    if (formControlEle) {
      this.renderer2.addClass(formControlEle, 'form-validation-border');
      this.renderer2.addClass(formControlEle, 'form-validation-label');
    }

  }

  private removeError(): void {
    const errorElement = document.getElementById(this.errorSpanId);
    if (errorElement) {
      errorElement.remove();
      let parent = this.renderer2.parentNode(this.elRef.nativeElement);
      let formControlEle = parent.querySelector('.form-control');
      let dropdownEle = parent.querySelector('.dropdown');
      if (dropdownEle) {
        let buttonEle = dropdownEle.querySelector('button');
        this.renderer2.removeClass(dropdownEle, 'form-validation-border');
        this.renderer2.removeClass(buttonEle, 'form-validation-label');
      }
      if (formControlEle) {
        this.renderer2.removeClass(formControlEle, 'form-validation-border');
        this.renderer2.removeClass(formControlEle, 'form-validation-label');
      }
    }
  }

}