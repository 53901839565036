import { Component, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import {
  NextPageService,
  NavigationLocation,
  PageLayout,
  SearchType,
  SliderType,
} from '@cvx/nextpage';
import { CalAngularService, ICvxClaimsPrincipal} from '@cvx/cal-angular';

import { IsLoadingService } from './shared/services/is-loading.service';
import { ToastObj } from './shared/interface/toast.interface';
import { ToastService } from './shared/services/toast.service';
import { Router } from '@angular/router';
import { AudTokenService } from './shared/services/aud-token.service';
import { environment } from 'src/environments/environment';
import { UserService } from './shared/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isNGLO!: boolean;
  env:string = "";
  title = 'appv14';
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  SearchType = SearchType;
  SliderType = SliderType;
  siteName! : string;
  isLoading!:boolean;
  toastObj :ToastObj = {
    show: false,
    type: '',
    msg: ''
  };
  routeUrl:string = '';
  showHeader:boolean = true;
  
  constructor(
    private NP: NextPageService,
    private authService: CalAngularService,
    private isLoadingSr: IsLoadingService,
    private audTokenSr: AudTokenService,
    private toastSr: ToastService,
    private location: Location,
    private router: Router,
    private userService: UserService
    ) {
      this.isLoading = false;
      router.events.subscribe((val) => {
        let addClass = document.getElementsByTagName("html")[0];
        this.routeUrl = this.location.path();

        if (this.routeUrl) {
          addClass.classList.remove("htmlOverFlow");
          this.showHeader = !this.routeUrl.includes('/caseManagement/case') || this.routeUrl.includes('/caseManagement/case/not-found');
          this.siteName = 'natural gas liquid optimizers';
        } else {
          addClass.classList.add("htmlOverFlow");
          this.siteName = 'optimizer dashboard';
        }
      });
      this.login();
      this.siteName = 'natural gas liquid optimizers';
        // For ease of use we reference the base service as NP.
        this.NP.options.sitename = "commodity movement optimizers";
        this.NP.options.navigationRoutes = [
            {
                title: 'published cases',
                path: 'nglo/publishedCases'
            },
            {
                title: 'case management',
                path: 'nglo/caseManagement'
            },
            {
              title: 'canal schedule',
              path: 'nglo/canalSchedule'
            },
            {
                title: 'master data',
                path: 'nglo/referenceData',
            },
            {
                title: 'zema pricing',
                path: 'nglo/pricingData',
            }
        ];
   }
   currentUserProfile! : ICvxClaimsPrincipal;
   loggedIn : boolean = false;

   ngOnInit(): void {
    const that = this;
     this.isLoadingSr.checkLoader$.subscribe(flag => {
      setTimeout(()=>{
        that.isLoading = flag;
      },0)
     });
     this.toastSr.checkToastNotification$.subscribe(obj => {
      this.toastObj = obj;
     })

     this.indicateEnvironment();
   }

   indicateEnvironment() {
      if (window.location.href.includes("localhost")) {
        this.env = "local";
      } else {
        switch (environment.environmentName) {
          case "dev":
            this.env = "development";
            break;
          case "test":
            this.env = "test";
            break;
          case "prod":
            this.env = "prod";
            break;
          default:
            this.env = "error";
         }
      }
   }

   login() {
    this.authService.userInitiatedSignIn()
    .subscribe({
      next: (user: any) => {
      this.loggedIn = user?.isUser;
      if (this.loggedIn) {
        this.currentUserProfile = this.authService.cvxClaimsPrincipal;
        this.userService.userProfile = this.authService.cvxClaimsPrincipal;
        console.log(`Welcome ${this.currentUserProfile.cai}`, this.currentUserProfile)
        this.getToken([environment.tokenURL]);
      }
      else {
        console.log("user not yet signed in");
      }
      },error: (error) => {
        console.log(error);
      },complete: ()=> {
        console.log('Login Completed');
      }
    })
    
  }

  private getToken(scopes: string[])
  {
    this.authService.getAADToken(scopes, false)
      .subscribe({
        next: (response) =>{
          const token = response?response:'';
          this.audTokenSr.setAudToken(token);
        },
        error: (err)=>{
          console.log('Unable to create audience token');
          console.log(err);
        }
      });
  }

  onActivate(event: any) {
    this.isNGLO = event.isNGLO;
  }
}
